import 'devextreme/dist/css/dx.material.blue.dark.compact.css';
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import config from 'devextreme/core/config'; 
import { licenseKey } from './devextreme-license';
import VueMatomo from 'vue-matomo'

config({ licenseKey });

createApp(App).use(VueMatomo, {
    // Configuration Options
    host: 'https://matomo.philipp-development.at/',
    siteId: 9,
}).mount('#app')

window._paq.push(['trackPageView']) // Um eine Seitenansicht aufzuzeichnen
<template>
  <div>
    <div class="form">
      <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">Start Datum</div>
            <div class="dx-field-value">
              <DxDateBox
                @value-changed="onStartValueChanged"
                type="datetime"
                pickerType="calendar"
              />
            </div>
          </div>
        </div>
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">End Datum</div>
            <div class="dx-field-value">
              <DxDateBox
              @value-changed="onEndValueChanged"
                type="datetime"
                pickerType="calendar"
              />
            </div>
          </div>
        </div>
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">Fixe Höhe</div>
            <div class="dx-field-value">
              <DxSwitch 
                v-model:value="isFixedRangeEnabled"
                :onText="'On'"
                :offText="'Off'"
              />
            </div>
          </div>
      </div>
    </div>
    <DxChart :dataSource="chartData">
      <DxArgumentAxis argumentType="datetime"/>
      <DxValueAxis :visualRange="isFixedRangeEnabled ? { startValue: -1, endValue: 20 } : { startValue: null, endValue: null }">
        <DxConstantLine value="10" color="green" dashStyle="dash" width="2">
          <DxLabel visible="true" text="Threshold" />
        </DxConstantLine>
        <DxConstantLine value="17" color="red" dashStyle="dash" width="2">
          <DxLabel visible="true" text="Threshold" />
        </DxConstantLine>
      </DxValueAxis>
      <DxSeries valueField="price" argumentField="startDate" type="line"/>
      <DxTooltip 
        enabled="true"
        :customizeTooltip="customizeTooltip"
        format="currency"
        shared="true"
      />
    </DxChart>
  </div>
</template>

<script>
import { DxChart, DxArgumentAxis, DxSeries, DxTooltip, DxValueAxis, DxConstantLine } from 'devextreme-vue/chart';
import DxSwitch from 'devextreme-vue/switch';
import DxDateBox from 'devextreme-vue/date-box';

export default {
  components: {
    DxChart,
    DxArgumentAxis,
    DxSeries,
    DxTooltip,
    DxValueAxis,
    DxConstantLine,
    DxSwitch,
    DxDateBox
  },
  data() {
    return {
      isFixedRangeEnabled: true,
      dateRange: [new Date(), new Date()],
      chartData: [],
      startDate: null,
      endDate: null
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    onStartValueChanged(e) {
      this.startDate = e.value.getTime();
      if (this.endDate) {
        fetch('https://api.awattar.at/v1/marketdata?start=' + this.startDate + '&end=' + this.endDate)
        .then(response => response.json())
        .then(data => {
          this.chartData = this.formatChartData(data.data);
        })
        .catch(error => console.error('Error fetching data:', error));
      }
    },
    onEndValueChanged(e) {
      this.endDate = e.value.getTime();
      if (this.startDate) {
        fetch('https://api.awattar.at/v1/marketdata?start=' + this.startDate + '&end=' + this.endDate)
        .then(response => response.json())
        .then(data => {
          this.chartData = this.formatChartData(data.data);
        })
        .catch(error => console.error('Error fetching data:', error));
      }
    },
    loadData() {
      fetch('https://api.awattar.at/v1/marketdata')//?start=1700126006000&end=1700734406000')
        .then(response => response.json())
        .then(data => {
          this.chartData = this.formatChartData(data.data);
        })
        .catch(error => console.error('Error fetching data:', error));
    },
    customizeTooltip(pointInfo) {
      return {
        html: `<strong>Date:</strong> ${pointInfo.argumentText}<br/>
               <strong>Price:</strong> ${pointInfo.value} ct/kWh`
      };
    },
    formatChartData(rawData) {
      return rawData.map(item => ({
        startDate: new Date(item.start_timestamp),
        endDate: new Date(item.end_timestamp),
        price: ((((item.marketprice / 1000) * 100) * 1.03) + 1.5) * 1.2,
      })).filter(item => item.startDate.getTime() && item.endDate.getTime());
    }
  }
};
</script>